import React from 'react';
import './EventDescription.scss';
import {HiOutlineLocationMarker} from "react-icons/hi";
import {getClosestEvent} from "../../../components/SanityHandler/SanityHandler";

/**
 * Returns the event description for upcoming events
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EventDescription = (props) => {
    //sets up the requirements to handle parallax of event description on mobile
    // const ref = React.useRef(null);
    const [offset, setOffset] = React.useState(0);
    const [eventData, setEventData] = React.useState({});


    //sets up the event listener for the parallax of the event description on mobile
    React.useEffect(() => {
        let handleScroll;
        if (props.mobile === true) {
            handleScroll = () => {
                let result = window.pageYOffset;
                setOffset(result);
            }
        } else {
            handleScroll = () => {

            }
        }
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        }

    }, [props.mobile]);

    React.useEffect(() => {
        async function getEvent() {
            let event = await getClosestEvent().then((res) => {
                res.date = new Date(res.startTime).toLocaleDateString();
                res.startTime = new Date(res.startTime).toLocaleTimeString("en-GB", {hour: "2-digit", minute: "2-digit"});
                res.endTime = new Date(res.endTime).toLocaleTimeString("en-GB", {hour: "2-digit", minute: "2-digit"});
                setEventData(res);
            });

            console.log("result ", event);
        }
        getEvent().then();




    },[]);

    return (
        <div style={{transform: `translateY(${props.mobile ? (-(  offset - 1300 + 450) *0.8) : 0}px)` }} className={"FutureEvents"}>
            {eventData ?
                <div className={"EventDescription"}>
                    <h1>{eventData.name}</h1>

                    <p>{eventData.description}</p>

                    <h3>{eventData.date}</h3>

                    {eventData.location ? <h3>{eventData.location + " "}<a href={eventData.locationUrl}><HiOutlineLocationMarker/></a></h3> : null}

                    <h3>{eventData.startTime} - {eventData.endTime}</h3>

                    {/*<p>A night of celebrations is in order... you will not want to miss this one</p>*/}

                    {eventData.ticketUrl ? <div className={"EventLink"}>
                        <a href={eventData.ticketUrl}>Get Tickets Here!</a>
                    </div> : null}
                </div>
            : <p>Sorry there seems to be a problem loading the events, please try reloading the page!</p>}
        </div>
    );
}


export default EventDescription;
