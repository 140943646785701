import './EventsPage.scss';
// import {post_05_03_22, post_05_10_22, post_11_6_22, post_12_02_22, post_15_10_22, post_16_3_23, post_23_11_22, post_24_3_22, post_27_11_21, post_28_10_21, post_30_09_21, post_23_03_23, post_05_05_23, post_09_06_23} from "../../assets/posters/lowQ";
import EventScroller from "./EventScroller/EventScroller";
import React, {useEffect, useState} from 'react';
// import imageUrlBuilder from '@sanity/image-url';
import {getSanityData} from "../../components/SanityHandler/SanityHandler";
import ParallaxBackground from "../../components/ParallaxBackground";

// import * as url from "url";


// const upcomingEvents = [{title: 'Garden Groove', description: `SEMSU Presents: Garden Groove`, imgUrl: post_09_06_23, tag: 1, info: 's', genres:"UKG, House, 140, Breaks, Jungle and more!"}];

// const prevEvents = [
//     {title: 'All Girls on Deck', description: 'SEMSU Presents: All Girls On Deck', imgUrl: post_05_05_23, tag: 1, info: 'Returning to Sobar on 5th May, we\'ve got the hardest women in the southampton scene behind the decks, spinning some heat from a range of genres. Gonna be one hell of a night ❤️‍🔥', url:"https://www.fatsoma.com/e/4r0vojyk/semsu-presents-all-girls-on-deck", genres:"Techno-Breaks-Trance-House-Jungle-DNB-Electro-UKG-140-AND MORE..."},
// {title: 'Analogue Hours', description: 'In collaboration with OverDraft!',imgUrl: post_23_03_23, tag:1,
    //         info:'Come and get to know us over a beer and some tacos! Bring your own vinyls to spin, or test some of ours. All skill levels welcome and student discount available!'},
//     {title: 'Retrospect', description: 'SEMSU returns for Semester 2 with a bang!' , imgUrl: post_16_3_23, tag: 2, url: "https://www.fatsoma.com/e/ede6pj96/semsu-presents-retrospect"
//         ,info: "In collaboration with The Washroom Collective!\n" +
//             "Expect some hard UKG, Breaks, 140 and Techno downstairs from our Semsu residents, and classic Jungle and Drum & Bass from The Washroom Collective in Off Piste upstairs.\n" +
//             "We chose retrospect as we wanted to take a look into the past and play some underground classics without limiting ourselves to a specific time period.\n" +
//             "Buckle up for some filthy classics even your parents might know."
//         ,genres: "UKG|Breaks|Techno|140|Jungle|DnB"},
//     {title: 'SEMSU Residents', description: '5/03/22' , imgUrl: post_05_03_22, tag: 3},
//     {title: 'Welcome to the Underground', description: '5/10/22' ,  imgUrl: post_05_10_22, tag: 4},
//     {title: 'End of Exams Summer Blowout', description: '11/06/22' , imgUrl: post_11_6_22, tag: 5},
//     {title: 'SEMSU Residents', description: '12/02/22' , imgUrl: post_12_02_22, tag: 6},
//     {title: 'Day Garden Rave', description: '15/10/22' , imgUrl: post_15_10_22, tag: 7},
//     {title: 'R+F x SEMSU', description: '23/11/22' , imgUrl: post_23_11_22, tag: 8},
//     {title: 'ALL Girls On Deck', description: '24/03/22' , imgUrl: post_24_3_22, tag: 9},
//     {title: 'Student Takeover', description: '27/11/21' , imgUrl: post_27_11_21, tag: 10},
//     {title: 'SEMSU Presents: Interplanitary Criminal', description: '28/10/21' , imgUrl: post_28_10_21, tag: 11},
//     {title: 'SEMSU Presents: Hammer', description: '30/09/21' , imgUrl: post_30_09_21, tag: 11}
// ];

//sets up the sanity backend connection
// let PROJECT_ID = "tg8p4gry";
// let DATASET = "production";
// const builder = imageUrlBuilder({ projectId: PROJECT_ID, dataset: DATASET });


/**
 * The contents of the Events page
 * @returns {JSX.Element}
 * @constructor
 */
const EventsPage = () => {
    //sets up the past and future project arrays
    const [pastProjects, setPastProjects] = useState([]);
    const [upcomingProjects, setUpcomingProjects] = useState([]);

    //On load fetches the data from sanity and sorts it into past and future events
    useEffect(() => {
        const fetchData = async () => {
            const fetchedProjects = await getSanityData();
            if(!fetchedProjects) return;
            const now = new Date(); // Get current date and time

            const pastProjectsData = fetchedProjects.filter((project) => {
                const eventDate = new Date(project.startTime);
                return eventDate < now;
            });

            setPastProjects(pastProjectsData);

            const upcomingProjectsData = fetchedProjects.filter((project) => {
                const eventDate = new Date(project.startTime);
                return eventDate >= now;
            });

            setUpcomingProjects(upcomingProjectsData);
        };
        fetchData().then();
    }, []);

    return (
        <>
            <ParallaxBackground/>
            <div className={"surrounderOfEvents"}>
                {upcomingProjects.length>0 ? <EventScroller class={"Future"} arrayOfEvents={upcomingProjects}/> : null}
                <EventScroller titleText={""} class={"Past"} arrayOfEvents={pastProjects}/>
            </div>
        </>
    );
}

export default EventsPage;